import axios from '@/plugins/axios';
import routes from "@/plugins/routes";

export function useActionLogs() {
    const getActionLogs = (page, limit, authorId, userId, storeId, invoiceOrderId, invoiceOrderBoxId, invoiceOrderItemId) => {
        return axios
            .get(routes.actionLogs.getLogs(page, limit, authorId, userId, storeId, invoiceOrderId, invoiceOrderBoxId, invoiceOrderItemId))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    return {
        getActionLogs
    }
}
