<template>
    <HugoLines />
    <Header />
    <div class="w-full flex flex-col bg-background-1 min-h-[100dvh] overflow-hidden relative">
        <div class="w-full flex justify-between items-center p-4 box-border">
            <div class="flex">
                <img src="../assets/chevron-left-svgrepo-com.svg" class="w-8 -ml-2" @click="router.push({name: 'dashboard'})" />
                <div class="text-3xl font-medium">Invoices</div>
            </div>
            <Button dimensions="small" label="Upload new" @click="router.push('/new-invoice-order')" />
        </div>

        <div v-if="loading">Loading...</div>
        <div class="w-full" v-if="invoices.length > 0">
            <div
                v-for="invoice in invoices"
                :key="invoice.id"
                class="w-full box-border flex justify-between p-4 items-center bg-white rounded mt-1 cursor-pointer border-[2px] border-background-1"
            >
                <div class="w-full flex flex-col" @click="viewInvoice(invoice)">
                    <div class="text-[16px]">{{invoice.name}}</div>
                    <div class="w-full flex justify-between mt-3">
                        <div class="text-md font-bold uppercase" :class="[invoice.status === 'PENDING' ? 'text-red-500' : 'text-green-700']">
                            {{invoice.status === 'PENDING' ? 'Pending' : 'Paid'}}
                        </div>
                        <div class="text-md font-medium">{{createdDate(invoice.created_at)}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full min-h-[500px] flex items-center justify-center" v-else>
            <div class="text-center">No invoices found</div>
        </div>
    </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import HugoLines from "@/components/HugoLines.vue";
import { format } from "date-fns";
import { useInvoiceOrder } from "@/composables/useInvoiceOrder";
import {onBeforeMount, ref} from "vue";
import Button from "@/components/Button.vue";
import {useRouter} from "vue-router";

const router = useRouter();
const { getAllInvoiceOrders } = useInvoiceOrder();

const invoices = ref([])
const loading = ref(true);

function createdDate(dateString) {
    const date = new Date(dateString);

    return format(date, "yyyy-MM-dd");
}

function viewInvoice(invoice) {
    if(invoice.status === 'PENDING') {
        return router.push(`/invoice-order/${invoice.id}`)
    }
    router.push(`/order-summary/${invoice.id}`);
}

onBeforeMount(() => {
    getAllInvoiceOrders()
        .then((res) => {
            loading.value = false;
            invoices.value = res.reverse();
        })
        .catch((err) => {
            console.log(err);
        })
        .finally(() => {
            loading.value = false;
        });
});
</script>
