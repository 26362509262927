export default {
    auth: {
        login: "/login",
        logout: "/logout",
    },
    user: {
        getUser: "/user",
        getUserById: (id) => "/user/" + id,
        getUsers: "/users",
        assignStore: "/users/assign-store",
        createUser: "/users",
        deleteUser: (id) => "/users/" + id,
        updateUser: (id) => "/users/" + id,
    },
    stores: {
        getStores: "/stores",
        getStore: (id) => "/stores/" + id,
        createStore: "/stores",
        deleteStore: (id) => "/stores/" + id,
        updateStore: (id) => "/stores/" + id,
    },
    invoiceOrder: {
        create: "/invoice-orders",
        createBox: (invoiceID) => "/invoice-orders/" + invoiceID + "/boxes",
        getAll: "/invoice-orders",
        get: (id) => "/invoice-orders/" + id,
        close: "/invoice-orders/close",
        batchScan: "/invoice-orders/batch-scan",
        getBoxes: (id) => "/invoice-orders/" + id + "/boxes",
        getBox: (invoiceID, boxID) => "/invoice-orders/" + invoiceID + "/boxes/" + boxID,
        getItemsInBoxes: (invoiceID, boxID) =>
            "/invoice-orders/" + invoiceID + "/boxes/" + boxID + "/items",
        markBoxAsCompleted: "/invoice-order-box/close",
        markInvoiceOrderAsCompleted: "/invoice-orders/close",
        resetBox: (invoiceID, boxID) =>
            "/invoice-orders/" + invoiceID + "/boxes/" + boxID + "/reset",
        removeScannedItem: (itemID) => "/invoice-order-item/" + itemID,
    },
    nir: {
        receivedItems: (boxID, eur) => `/invoice-orders/boxes/${boxID}/nir-received-items?eur=${eur}`,
        differences: (boxID, eur) => `/invoice-orders/boxes/${boxID}/nir-differences?eur=${eur}`,
    },
    actionLogs: {
        getLogs: (page = 1, limit = 10, authorId, userId, storeId, invoiceOrderId, invoiceOrderBoxId, invoiceOrderItemId) => {
            let searchParams = new URLSearchParams({
                page,
                limit: limit,
            });

            if (authorId) {
                searchParams.append('authorId', authorId);
            }

            if(storeId) {
                searchParams.append('storeId', storeId);
            }

            if(userId) {
                searchParams.append('userId', userId);
            }

            if(invoiceOrderId) {
                searchParams.append('invoiceOrderId', invoiceOrderId);
            }

            if(invoiceOrderBoxId) {
                searchParams.append('invoiceOrderBoxId', invoiceOrderBoxId);
            }

            if(invoiceOrderItemId) {
                searchParams.append('invoiceOrderItemId', invoiceOrderItemId);
            }

            return `/action-logs/?` + searchParams.toString();
        },
    }
};
