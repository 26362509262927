<template>
    <HugoLines/>
    <Header/>
    <div class="w-full flex flex-col bg-background-1 min-h-[100vh]">
        <div class="w-full flex flex-col p-5 box-border">
            <div class="flex flex-col mt-10">
                <span class="text-md">PASUL 1</span>
                <h1 class="text-3xl font-medium mb-4">Incarca document</h1>
            </div>
            <div
                class="w-full rounded border border-default-1 border-dashed h-[200px]"
                :class="dragoverClass"
                @dragenter="onDragEnter"
                @drop="onDragLeave"
                @drop.prevent="handleFiles($event, 'drop')"
                @dragover.prevent
                :draggable="false"
            >
                <div
                    v-if="!fileToBeUploaded"
                    class="w-full h-full flex flex-col items-center justify-center bg-white"
                >
                    <img src="@/assets/upload.svg" alt="Upload" class="mb-4 w-12"/>
                    <label
                        for="file-upload"
                        class="w-full flex flex-col items-center justify-center"
                    >
                        <div class="w-1/2 text-center text-default-1">
                            Trage documentul aici sau apasă pentru a încărca
                        </div>
                        <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            :multiple="false"
                            ref="file"
                            @change="handleFiles($event, 'upload')"
                            class="hidden"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                    </label>
                </div>
                <div
                    v-else
                    class="w-full h-full flex flex-col items-center justify-center bg-white"
                >
                    {{ fileToBeUploaded.name }}
                </div>
            </div>
            <Button
                label="Incarca"
                class="mt-4"
                @click="uploadInvoiceReport"
                :loading="loading"
                :disabled="!fileToBeUploaded"
            />
        </div>
    </div>
</template>

<script setup>
import Button from '@/components/Button.vue';
import {onMounted, ref} from 'vue';
import {useInvoiceOrderStore} from '@/store/useInvoiceOrderStore';
import {useInvoiceOrder} from '@/composables/useInvoiceOrder';
import router from '@/router';
import {toast} from 'vue3-toastify';
import {storeToRefs} from 'pinia';
import Header from "@/components/Header.vue";
import HugoLines from "@/components/HugoLines.vue";
import Swal from "sweetalert2";

const {invoiceOrder} = storeToRefs(useInvoiceOrderStore());
const {createInvoiceOrder} = useInvoiceOrder();

const loading = ref(false);

const dragoverClass = ref(null);

const TEN_MB = 1e7;
const ACCEPTED_FORMATS = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

function onDragEnter() {
    dragoverClass.value = 'border border-dashed border-primary-1';
}

function onDragLeave() {
    dragoverClass.value = '';
}

const fileToBeUploaded = ref(null);

function handleFiles(event, type) {
    const file =
        type === 'upload' ? event.target.files[0] : event.dataTransfer.files[0]; // Just pick the first file

    if (!file) return;

    console.log(file);

    if (file.size > TEN_MB) {
        Swal.fire({
            title: 'Eroare',
            text: 'Fisierul este prea mare. Incarca un fisier mai mic de 10MB.',
            icon: 'error',
        });
        return;
    }

    if (!ACCEPTED_FORMATS.includes(file.type)) {
        Swal.fire({
            title: 'Eroare',
            text: 'Fisierul nu este in formatul corect. Incarca un fisier de tipul .xlsx.',
            icon: 'error',
        });
        return;
    }

    fileToBeUploaded.value = file;
}

function uploadInvoiceReport() {
    loading.value = true;

    const payload = {
        file: fileToBeUploaded.value,
    };

    createInvoiceOrder(payload)
        .then((response) => {
            invoiceOrder.value = {
                ...response,
                filename: fileToBeUploaded.value.name,
            };
            router.push({name: 'invoice-order', params: {id: response.id}});
        })
        .catch((error) => {
            Swal.fire({
                title: 'Eroare',
                text: 'Am intampinat o eroare la incarcarea documentului. Asta se poate intampla cand nu exista un produs pentru magazinul curent sau nu exista un magazin legat de contul cu care sunteti autentificat.',
                icon: 'error',
            });
            console.error(error);
        })
        .finally(() => {
            loading.value = false;
        });
}
</script>
