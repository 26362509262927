<template>
    <HugoLines />
    <Header />

    <div
        class="w-full flex flex-col bg-background-1 min-h-[100dvh] overflow-hidden relative"
    >
        <div class="w-full flex justify-between items-center p-4 box-border">
            <div class="flex">
                <img
                    src="../../assets/chevron-left-svgrepo-com.svg"
                    class="w-8 -ml-2"
                    @click="router.push({ name: 'dashboard' })"
                />
                <div class="text-3xl font-medium">Stores</div>
            </div>
            <Button
                v-if="isCurrentUserAdmin"
                dimensions="small"
                label="Add store"
                @click="openAddStoreModal"
            />
        </div>

        <div class="w-full flex flex-col">
            <div
                v-for="store in stores"
                :key="store.id"
                class="flex justify-between p-4 bg-white rounded mt-1 cursor-pointer"
                @click="viewStore(store.id)"
                v-touch:swipe.left="
                    () => {
                        doDeleteStore(store.id);
                    }
                "
            >
                <div>
                    <div>
                        <div>
                            {{ store.name }}
                        </div>
                    </div>
                    <div>
                        <div>
                            <font-awesome-icon :icon="['fas', 'user']" />
                            {{ store.users.length }}
                        </div>
                    </div>
                </div>
                <div class="flex justify-between items-center text-2xl">
                    <div class="text-center text-violet-600">
                        <font-awesome-icon :icon="['fas', 'pen']" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="loading">Loading...</div>

    <!-- ADD STORE MODAL -->
    <Modal ref="modal">
        <template #header>
            <h3 class="text-2xl leading-6 font-medium text-gray-900 p-2">
                Add store
            </h3>
        </template>
        <template #default>
            <div class="bg-red-400 text-white p-2" v-if="hasErrors">
                Please fill in the details
            </div>
            <div class="w-full bg-white p-2">
                <div class="m-2">
                    <label for="name"
                        >Name:
                        <input
                            type="text"
                            v-model="storeName"
                            class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-2"
                    /></label>
                </div>
                <div class="m-2">
                    <label for="code"
                        >Code:
                        <input
                            type="number"
                            v-model="storeCode"
                            class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-2"
                    /></label>
                </div>
            </div>
        </template>
        <template #footer>
            <Button
                label="Add store"
                color="gold"
                class="mt-4"
                @click="addStore"
            />
            <Button
                label="Close"
                color="grey"
                class="mt-4"
                @click="closeAddStoreModal"
            />
        </template>
    </Modal>
</template>

<script setup>
import Header from "@/components/Header.vue";
import HugoLines from "@/components/HugoLines.vue";
import Modal from "@/components/Modal.vue";
import Button from "@/components/Button.vue";

import { useStores } from "@/composables/useStores";
import { useUserStore } from "@/store/useUserStore";
import { storeToRefs } from "pinia";

import Swal from "sweetalert2";
import router from "@/router";
import { computed, onMounted, ref } from "vue";

const { getStores, createStore, deleteStore } = useStores();
const { user } = storeToRefs(useUserStore());

const stores = ref([]);
const loading = ref(true);
const storeName = ref("");
const storeCode = ref("");
const hasErrors = ref(false);
const modal = ref(null);

function fetchStoresData() {
    getStores()
        .then((res) => {
            loading.value = false;

            stores.value = res;
        })
        .catch(() => {
            loading.value = false;
        });
}

function openAddStoreModal() {
    storeName.value = "";
    storeCode.value = "";
    modal.value.openModal();
}

function closeAddStoreModal() {
    hasErrors.value = false;
    modal.value.closeModal();
}

function viewStore(id) {
    router.push({ name: "view-store", params: { storeId: id } });
}

function doDeleteStore(id) {
    Swal.fire({
        title: "Sterge magazin",
        text: "Esti sigur ca vrei sa stergi magazinul?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Da, sterge-l",
        cancelButtonText: "Nu",
    }).then((result) => {
        if (result.isConfirmed) {
            deleteStore(id)
                .then(() => {
                    Swal.fire({
                        title: "Sters!",
                        text: "Magazinul a fost sters cu succes.",
                        icon: "success",
                    });
                    stores.value = stores.value.filter((store) => store.id !== id);
                })
                .catch(() => {
                    Swal.fire({
                        text: "Ceva nu a mers bine",
                        timer: 2000,
                        timerProgressBar: true,
                        icon: "error",
                        showConfirmButton: false,
                    });
                });
        }
    });
}

function addStore() {
    hasErrors.value = false;
    if (storeName.value === "" || storeCode.value === "") {
        hasErrors.value = true;
        return;
    }
    const payload = { name: storeName.value, code: storeCode.value };
    createStore(payload)
        .then(() => {
            modal.value.closeModal();
            Swal.fire({
                title: "Adaugat!",
                text: "Magazinul a fost adaugat cu succes.",
                icon: "success",
            });
            fetchStoresData();
        })
        .catch(() => {
            Swal.fire({
                text: "Ceva nu a mers bine. Incearca din nou.",
                timer: 2000,
                timerProgressBar: true,
                icon: "error",
                showConfirmButton: false,
            });
        });
}

const isCurrentUserAdmin = computed(() => {
    if (!user.value) {
        return false;
    }
    return user.value.is_admin;
});

onMounted(() => {
    if (user.value && !user.value.is_admin) {
        router.push({ name: "dashboard" });
    }

    getStores()
        .then((res) => {
            loading.value = false;
            stores.value = res;
        })
        .catch((err) => {
            loading.value = false;
            console.log(err);
        });
});
</script>
