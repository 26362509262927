import axios from 'axios';

const instance = axios.create({
	baseURL: 'https://hugo.opsolutions.ro/', // Your API base URL
});

instance.interceptors.request.use(
	config => {
		// Fetch the token from localStorage
		const token = localStorage.getItem('accessToken');

		// If the token exists, modify the request configuration to include the Bearer token
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}

		return config;
	},
	error => {
		// Handle the error (if you need to)
		return Promise.reject(error);
	}
);

export default instance;