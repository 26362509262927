<template>
<div class="w-full flex pb-10 justify-between mt-5">
    <router-link
        :to="`/history/${ prevPage }`"
        :class="hasPrevPage ? 'cursor-pointer' : 'cursor-not-allowed opacity-20 pointer-events-none'"
        class="py-2 px-3 box-border text-center bg-white border border-black/20 flex items-center justify-between"
    >
        <span>&lt; Previous page</span>
    </router-link>
    <router-link
        :to="`/history/${ nextPage }`"
        :class="hasNextPage ? 'cursor-pointer' : 'cursor-not-allowed opacity-20 pointer-events-none'"
        class="py-2 px-3 box-border text-center bg-white border border-black/20 flex items-center justify-between">
        <span>Next page ></span>
    </router-link>
</div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    meta: {
        type: Object,
        required: true
    },
    currentPage: {
        type: String,
        required: true
    }
});

const hasNextPage = computed(() => {
    if(!props.meta) {
        return false;
    }
    return props.meta.current_page < props.meta.last_page
})

const nextPage = computed(() => {
    if(!props.meta) {
        return 1;
    }
    return parseInt(props.currentPage) + 1
})

const hasPrevPage = computed(() => {
    if(!props.meta) {
        return false;
    }
    return props.meta.current_page > 1
})

const prevPage = computed(() => {
    if(!props.meta) {
        return 1;
    }
    return parseInt(props.currentPage) - 1
})

const currentPage = computed(() => {
    if(!props.meta) {
        return 1;
    }
    return props.meta.current_page
})

</script>
