<template>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="emit('close-modal')" class="relative z-10">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black/25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex min-h-full items-center justify-center p-4 text-center"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                        >
                            <DialogTitle
                                as="h3"
                                class="text-lg font-medium leading-6 text-gray-900"
                            >
                                Manually add code
                            </DialogTitle>
                            <div class="mt-2">
                                <p class="text-sm text-gray-500">
                                    Type the EAN / SSCC code to manually add it to the scanned list of {{type}}.
                                </p>
                            </div>

                            <input v-model="code" type="text" placeholder="Code" class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-2 mt-4" />

                            <div class="mt-4">
                                <Button
                                    label="Add"
                                    color="grey"
                                    class="mt-4"
                                    @click="emit('manual-scan', code)"
                                />
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { ref } from 'vue'
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from '@headlessui/vue'
import Button from "@/components/Button.vue";

const emit = defineEmits(['close-modal', 'manual-scan'])

const props = defineProps({
    type: {
        type: String,
        default: 'items',
    }
})

const isOpen = ref(true)

const code = ref('');
</script>
