<template>
    <div class="w-full relative">
        <HugoLines />
        <Header />
        <div class="w-full flex flex-col box-border mb-6 bg-background-1" v-if="box">
            <div class="bg-white w-full px-4 mt-4">
                <div class="w-[30px] mt-4 mb-2 flex items-center justify-center">
                    <img src="../assets/chevron-left-svgrepo-com.svg" class="w-[30px] -ml-4" @click="router.push(`/invoice-order/${route.params.invoiceID}`)"/>
                </div>
                <div class="flex flex-col">
                    <span class="text-md">SCANARE COMPLETA</span>
                    <h1 class="text-3xl font-bold mb-4">BOX {{box.code}}</h1>
                </div>
            </div>
            <div class="w-full bg-white px-4 py-4 mt-4 flex-col">
                <div class="text-lg mb-3 font-medium">Generare NIR-uri</div>
                <div class="w-full">
                    <span class="text-sm text-gray-500">CURS EURO</span>
                    <input v-model="euro" type="number" class="w-full h-[50px] border border-default-1 rounded-md px-4" placeholder="Curs euro">
                    <div class="text-xs leading-4 text-gray mt-1 mb-5">Este necesar introducerea unei valori in campul de curs euro inainte de a genera un Nir</div>
                </div>
                <div class="w-full flex">
                    <Button label="NIR produse primite" @click="generateNirReceived" :disabled="isGenerateDisabled" />
                    <div class="w-4"></div>
                    <Button label="NIR diferente" color="grey" @click="generateNirDifferences" :disabled="isGenerateDisabled"/>
                </div>
            </div>
            <div class="w-full flex flex-col p-4 mt-4 bg-white">
                <div class="text-lg mb-3 font-medium">Statistici</div>
                <div class="w-full grid grid-cols-2 grid-row-2 gap-4">
                    <div class="p-5 box-border bg-background-1 flex items-center justify-center flex-col">
                        <span>Total produse</span>
                        <span class="text-2xl font-bold">{{box.counts.total}}</span>
                    </div>
                    <div class="p-5 box-border bg-background-1 flex items-center justify-center flex-col">
                        <span>Produse scanate</span>
                        <span class="text-2xl font-bold">{{box.counts.scanned}}</span>
                    </div>
                    <div class="p-5 box-border bg-background-1 flex items-center justify-center flex-col">
                        <span>Produse lipsa</span>
                        <span class="text-2xl font-bold">{{box.counts.missing}}</span>
                    </div>
                    <div class="p-5 box-border bg-background-1 flex items-center justify-center flex-col">
                        <span>Produse in plus</span>
                        <span class="text-2xl font-bold">{{box.counts.extra}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full h-[500px] flex items-center justify-center" v-else>
            Loading box
        </div>
    </div>
</template>

<script setup>
import HugoLines from "@/components/HugoLines.vue";
import Header from "@/components/Header.vue";
import Button from "@/components/Button.vue";
import {computed, onMounted, ref} from "vue";
import {useRouter} from "vue-router";
import {useRoute} from "vue-router";
import {useInvoiceOrder} from "@/composables/useInvoiceOrder";
import Swal from "sweetalert2";

const {nirReceivedItems, nirDifferences, getBox} = useInvoiceOrder();

const router = useRouter();
const route = useRoute();

const euro = ref(0);

const generating = ref(false);
const file = ref('');

const box = ref(null);

function generateNirReceived() {
    generating.value = true;
    nirReceivedItems(route.params.boxID, euro.value)
        .then(() => {
            generating.value = false;
        })
        .catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Eroare',
                text: 'A aparut o eroare la generarea NIR-ului',
            });
        })
        .finally(() => {
            generating.value = false;
        });
}

function generateNirDifferences() {
    generating.value = true;
    nirDifferences(route.params.boxID, euro.value)
        .then(() => {
            generating.value = false;
        })
        .catch(() => {
            Swal.fire({
                icon: 'error',
                title: 'Eroare',
                text: 'A aparut o eroare la generarea NIR-ului',
            });
        })
        .finally(() => {
            generating.value = false;
        });
}

const isGenerateDisabled = computed(() => {
    return generating.value
});

onMounted(async () => {
    debugger;

    if(!route.params.invoiceID) {
        return router.push({name: 'invoice-orders'});
    }

    if(!route.params.boxID) {
        return router.push({name: 'invoice-order', params: {invoiceID: route.params.invoiceID}});
    }

    box.value = await getBox(route.params.invoiceID, route.params.boxID)

    if(box.value.status === 'PENDING') {
        return router.push({name: 'invoice-order', params: {invoiceID: route.params.invoiceID}});
    }
});

</script>

<style scoped>

</style>
