<template>
    <HugoLines />
    <Header />
    <div class="w-full flex flex-col bg-background-1 min-h-[100dvh] overflow-hidden relative">
        <div class="w-full flex justify-between p-4 box-border flex-col">
            <div class="flex">
                <img src="../assets/chevron-left-svgrepo-com.svg" class="w-8 -ml-2" @click="router.push({name: 'dashboard'})" />
                <div class="text-3xl font-medium">Istoric actiuni</div>
            </div>
            <div class="flex w-full flex-col">
                <Multiselect
                    class="mt-4"
                    v-model="selectedAuthorId"
                    :options="users"
                    placeholder="Autorul actiunii"
                    valueProp="id"
                    label="name"
                    searchable
                    close-on-select
                    :classes="classes"
                    @select="loadData"
                />

                <Multiselect
                    class="mt-3"
                    v-model="selectedUserId"
                    :options="users"
                    placeholder="Asupra cui s-a facut actiunea"
                    valueProp="id"
                    label="name"
                    searchable
                    close-on-select
                    :classes="classes"
                    @select="loadData"
                />

                <Multiselect
                    class="mt-3"
                    v-model="selectedStoreId"
                    :options="stores"
                    placeholder="Magazine"
                    valueProp="id"
                    label="name"
                    searchable
                    close-on-select
                    :classes="classes"
                    @select="loadData"
                />

                <Multiselect
                    class="mt-3"
                    v-model="selectedInvoiceOrderId"
                    :options="invoiceOrders"
                    placeholder="Invoice order"
                    valueProp="id"
                    label="name"
                    searchable
                    close-on-select
                    :classes="classes"
                    @select="loadBoxes"
                />

                <Multiselect
                    class="mt-3"
                    v-if="selectedInvoiceOrderId"
                    v-model="selectedBoxId"
                    :options="boxes"
                    placeholder="Selecteaza o cutie"
                    valueProp="id"
                    label="code"
                    searchable
                    close-on-select
                    :classes="classes"
                    @select="loadProducts"
                />

                <Multiselect
                    class="mt-3"
                    v-if="selectedBoxId"
                    v-model="selectedProductId"
                    :options="products"
                    placeholder="Selecteaza un produs"
                    valueProp="id"
                    label="name"
                    searchable
                    close-on-select
                    :classes="classes"
                    @select="loadData"
                />
            </div>

            <div class="w-full mt-8" v-if="logs.length > 0">
                <h2 class="text-2xl font-medium mb-4">Rezultate</h2>

                <Pagination :meta="logsMeta" :current-page="currentPage" />

                <div v-for="log in logs" :key="log.id">
                    <component :is="logsType(log.action)" :log="log" />
                </div>

                <Pagination :meta="logsMeta" :current-page="currentPage" />
            </div>
            <div class="w-full mt-8 min-h-[250px] flex items-center justify-center" v-else>

                <p class="text-center">Nu exista rezultate.<br>Selecteaza alte filtre pentru a modifica rezultatele.</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import HugoLines from "@/components/HugoLines.vue";
import {computed, nextTick, onBeforeMount, ref, watch} from "vue";
import CreateItemCard from "@/components/Logs/CreateItemCard.vue";

import {useRouter} from "vue-router";
import {useUser} from "@/composables/useUser";
import {useStores} from "@/composables/useStores";
import {useInvoiceOrder} from "@/composables/useInvoiceOrder";
import Multiselect from "@vueform/multiselect";
import Swal from "sweetalert2";
import {useActionLogs} from "@/composables/useActionLogs";
import ScannedItemCard from "@/components/Logs/ScannedItemCard.vue";
import CreateInvoiceOrderCard from "@/components/Logs/CreateInvoiceOrderCard.vue";
import DeleteItemCard from "@/components/Logs/DeleteItemCard.vue";
import ResetItemStatusCard from "@/components/Logs/ResetItemStatusCard.vue";
import Pagination from "@/components/Logs/Pagination.vue";
import {useRoute} from "vue-router";
import EditStoreCard from "@/components/Logs/EditStoreCard.vue";
import AssignStoreCard from "@/components/Logs/AssignStoreCard.vue";

const router = useRouter();
const route = useRoute();

const {getAllUsers} = useUser();
const {getStores} = useStores();
const {getAllInvoiceOrders, getAllBoxesForInvoiceOrder, getAllItemsForBox} = useInvoiceOrder();
const {getActionLogs} = useActionLogs();


const selectedAuthorId = ref(null);
const selectedUserId = ref(null);
const selectedStoreId = ref(null);
const selectedInvoiceOrderId = ref(null);
const selectedBoxId = ref(null);
const selectedProductId = ref(null);

const users = ref([]);
const stores = ref([]);
const invoiceOrders = ref([]);
const boxes = ref([]);
const products = ref([]);

const logs = ref([]);
const logsMeta = ref({});

function logsType(action) {
    switch (action) {
        case 'CREATE_ITEM':
            return CreateItemCard;
        case 'SCANNED_ITEM':
            return ScannedItemCard
        case 'CREATE_INVOICE_ORDER':
            return CreateInvoiceOrderCard
        case 'RESET_ITEM_STATUS':
            return ResetItemStatusCard
        case 'DELETE_ITEM':
            return DeleteItemCard
        case 'EDIT_STORE':
            return EditStoreCard
        case 'ASSIGN_STORE':
            return AssignStoreCard
    }
}


const classes = ref({
    container: 'multiselect text-body-6 text-default-1 placeholder:text-default-4 bg-white border h-10 w-full rounded text-left focus:outline-none focus:ring-transparent focus:border-primary-1',
    search: 'w-full h-10 absolute border-0 inset-0 outline-none focus:ring-0 appearance-none box-border text-default-1 text-body-6 bg-transparent rounded pl-3.5 rtl:pl-0 rtl:pr-3.5 hover:border-primary-1 hover:border',
    option: 'flex items-center justify-start box-border text-left cursor-pointer leading-snug pl-4 pr-2 py-2 focus:text-primary-1 text-body-6 text-default-1',
    optionPointed: 'hover:text-primary-1 cursor-pointer hover:bg-background-3',
    optionSelected: 'bg-background-3',
    optionDisabled: 'text-gray-300 cursor-not-allowed',
    optionSelectedPointed: 'bg-background-3 opacity-90',
    optionSelectedDisabled: 'bg-background-3 opacity-70 cursor-not-allowed pointer-events-none',
});

function loadData() {
    getActionLogs(currentPage.value, 10, selectedAuthorId.value, selectedUserId.value, selectedStoreId.value, selectedInvoiceOrderId.value, selectedBoxId.value, selectedProductId.value).then((response) => {
        logs.value = response.data;
        logsMeta.value = response.meta;
    }).catch(e => {
        Swal.fire({
            title: 'Error!',
            text: e.message,
            icon: 'error',
            confirmButtonText: 'Ok'
        });
    });
}

function loadBoxes() {
    selectedBoxId.value = null;
    selectedProductId.value = null;
    nextTick(() => {
        getAllBoxesForInvoiceOrder(selectedInvoiceOrderId.value).then((data) => {
            boxes.value = data;
            loadData();
        }).catch (e => {
            Swal.fire({
                title: 'Error!',
                text: e.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
        });
    })
}

function loadProducts() {
    selectedProductId.value = null;
    nextTick(() => {
        getAllItemsForBox(selectedInvoiceOrderId.value, selectedBoxId.value).then((data) => {
            products.value = data;
            loadData();
        }).catch (e => {
            Swal.fire({
                title: 'Error!',
                text: e.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        });
    })
}

const currentPage = computed(() => {
    return route.params.page ? route.params.page : 1;
})

watch(() => currentPage.value, (newVal, oldVal) => {
    loadData();
})

onBeforeMount(async () => {
    try {
        users.value = await getAllUsers();
        stores.value = await getStores();
        invoiceOrders.value = await getAllInvoiceOrders();
        invoiceOrders.value = invoiceOrders.value.reverse();
    } catch (e) {
        Swal.fire({
            title: 'Error!',
            text: e.message,
            icon: 'error',
            confirmButtonText: 'Ok'
        });
    }
})

</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
.multiselect {
    border-color:#EFF2F7;
    &.is-active {
        border: 1px solid var(--primary-color);
        box-shadow: none;
    }

    &.is-disabled {
        background: white;
        .multiselect-caret {
            display: none;
        }
    }
}

.multiselect-dropdown {
    max-height: 350px;
}

.multiselect-caret, .multiselect-clear-icon {
    z-index: 1;
}

.white-inset {
    -webkit-box-shadow: inset 0px 0px 0px 10px #FFFFFF;
    box-shadow: inset 0px 0px 0px 10px #FFFFFF;
}

.multiselect-inifite-spinner, .multiselect-spinner {
    background-color: var(--primary-color);
}
</style>
