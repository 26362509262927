import axios from "@/plugins/axios";
import routes from "@/plugins/routes";

export function useStores() {
    const getStores = () => {
        return axios
            .get(routes.stores.getStores)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const getStore = (id) => {
        return axios
            .get(routes.stores.getStore(id))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const createStore = (payload) => {
        return axios
            .post(routes.stores.createStore, payload)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const updateStore = (id, payload) => {
        return axios
            .put(routes.stores.updateStore(id), payload)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const deleteStore = (id) => {
        return axios
            .delete(routes.stores.deleteStore(id))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    return {
        getStores,
        getStore,
        createStore,
        updateStore,
        deleteStore,
    };
}
