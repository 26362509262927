<template>
    <Menu as="div" class="relative inline-block text-left">
        <div>
            <MenuButton
                class="inline-flex w-full justify-center rounded-md bg-black/20 pl-4 py-2 focus:outline-none bg-transparent"
            >
                <img src="@/assets/three-dots.svg" class="w-6"/>
            </MenuButton>
        </div>

        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-in"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0"
        >
            <MenuItems
                class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
            >
                <div class="px-1 py-1">
                    <MenuItem v-slot="{ active }">
                        <button
                            :class="[
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900',
                                            'group flex w-full rounded-md px-2 py-3 text-sm text-left',
                                        ]"
                            @click="emit('reset-scan')"
                        >
                            Reseteaza scanarea
                        </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" v-if="!isScanComplete">
                        <button
                            :class="[
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900',
                                            'group flex w-full rounded-md px-2 py-3 text-sm text-left',
                                        ]"
                            @click="emit('mark-box-scan-as-completed')"
                        >
                            Marcheaza scanarea cutiei ca fiind completa
                        </button>
                    </MenuItem>
                    <MenuItem v-slot="{ active }" as="div" v-if="hasPendingItems">
                        <button
                            :class="[
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900',
                                            'group flex w-full rounded-md px-2 py-3 text-sm text-left',
                                        ]"
                            @click="emit('upload-pending-items')"
                        >
                            Incarca produsele in asteptare
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>

</template>

<script setup>
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import Button from "@/components/Button.vue";
import {useInvoiceOrderStore} from "@/store/useInvoiceOrderStore";
import {storeToRefs} from "pinia";

const {isScanComplete} = storeToRefs(useInvoiceOrderStore());

const emit = defineEmits(["reset-scan", "mark-box-scan-as-completed"]);

const props = defineProps({
    hasPendingItems: {
        type: Boolean,
        default: false
    }
});
</script>
