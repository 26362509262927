import axios from "@/plugins/axios";
import routes from "@/plugins/routes";

export function useInvoiceOrder() {
    const createInvoiceOrder = (payload) => {
        return axios
            .post(routes.invoiceOrder.create, payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const createBox = (invoiceID, payload) => {
        return axios
            .post(routes.invoiceOrder.createBox(invoiceID), payload, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    const getInvoiceOrder = (id) => {
        return axios
            .get(routes.invoiceOrder.get(id))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const getAllInvoiceOrders = () => {
        return axios
            .get(routes.invoiceOrder.getAll)
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const getAllBoxesForInvoiceOrder = (id) => {
        return axios
            .get(routes.invoiceOrder.getBoxes(id))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    const getAllItemsForBox = (invoiceID, boxID) => {
        return axios
            .get(routes.invoiceOrder.getItemsInBoxes(invoiceID, boxID))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const batchScan = (payload) => {
        return axios
            .post(routes.invoiceOrder.batchScan, payload, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const markBoxAsCompleted = (payload) => {
        return axios
            .post(routes.invoiceOrder.markBoxAsCompleted, payload, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    const markInvoiceOrderAsCompleted = (payload) => {
        return axios
            .post(routes.invoiceOrder.markInvoiceOrderAsCompleted, payload, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    const resetBox = (invoiceID, boxID) => {
        return axios
            .post(routes.invoiceOrder.resetBox(invoiceID, boxID))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    const nirReceivedItems = (boxID, eur) => {
        return axios
            .get(routes.nir.receivedItems(boxID, eur),{
                responseType: 'blob'
            })
            .then(response => {
                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement('a');

                anchorElement.href = href;
                console.log(response.headers);
                anchorElement.download = response.headers['content-disposition'].split('filename=')[1];

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
                return Promise.resolve()
            })
            .catch(error => {
                console.log('error: ', error);
                return Promise.reject(error);
            });
    }

    const nirDifferences = (boxID, eur) => {
        return axios
            .get(routes.nir.differences(boxID, eur), {
                responseType: 'blob'
            })
            .then((response) => {
                const href = window.URL.createObjectURL(response.data);

                const anchorElement = document.createElement('a');

                anchorElement.href = href;
                console.log(response.headers);
                anchorElement.download = response.headers['content-disposition'].split('filename=')[1];

                document.body.appendChild(anchorElement);
                anchorElement.click();

                document.body.removeChild(anchorElement);
                window.URL.revokeObjectURL(href);
                return Promise.resolve()
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    const getBox = (invoiceID, boxID) => {
        return axios
            .get(routes.invoiceOrder.getBox(invoiceID, boxID))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    };

    const removeScannedItem = (itemID) => {
        return axios
            .delete(routes.invoiceOrder.removeScannedItem(itemID))
            .then((response) => {
                return Promise.resolve(response.data);
            })
            .catch((err) => {
                return Promise.reject(err);
            });
    }

    return {
        createInvoiceOrder,
        createBox,
        batchScan,
        getInvoiceOrder,
        getAllItemsForBox,
        getAllInvoiceOrders,
        getAllBoxesForInvoiceOrder,
        markBoxAsCompleted,
        markInvoiceOrderAsCompleted,
        resetBox,
        nirReceivedItems,
        nirDifferences,
        getBox,
        removeScannedItem
    };
}
