<template>
    <div class="w-full h-[100dvh] fixed bg-black z-20 top-0">
        <div
            class="w-[40px] h-[40px] bg-white flex items-center justify-center text-2xl rounded-full absolute top-6 right-6 left-auto z-[9999]"
            @click="cancelScan"
        >
            <div class="-mt-1">x</div>
        </div>

        <div
            class="w-[40px] h-[40px] bg-white flex items-center justify-center text-2xl rounded-full absolute top-[80px] right-6 left-auto z-[9999]"
            @click="restartScan"
        >
            <img src="@/assets/restart.svg" alt="Restart scan" class="w-6" />
        </div>
        <div class="w-full relative scanner-container" ref="scannerContainer"></div>
        <transition name="slide">
            <div
                class="flex items-center flex-col w-full absolute bottom-0 min-h-[250px] px-6 box-border bg-white rounded-t-xl pt-6 z-20"
                v-if="scannedCode"
            >
                <span class="text-lg text-center">A fost descoperit codul</span>
                <h1 class="text-2xl font-bold mt-4 whitespace-normal break-all">{{ scannedCode }}</h1>
                <Button label="Salveaza" class="mt-6" color="gold" @click="completeScan"/>
                <Button label="Salveaza si continua scanarea" class="mt-4" color="black" @click="completeAndContinueScan"/>
            </div>
        </transition>
    </div>
</template>

<script setup>
import Quagga from '@ericblade/quagga2';
import {computed, onUnmounted, ref} from "vue";
import Button from "@/components/Button.vue";
import {useBeep} from "@/composables/useBeep";

// audio.lopp = false;

const emit = defineEmits(['addToScannedItems', 'cancelScan', 'addToScannedItemsAndContinue'])

const props = defineProps({
    decoder: {
        type: String,
        default: 'code_128_reader'
    }
});

const scannerContainer = ref(null);
const scannedCode = ref(null);

async function startScan() {
    const numOfCores = navigator.hardwareConcurrency;
    Quagga.init({
        inputStream: {
            name: "Live",
            type: "LiveStream",
            target: scannerContainer.value // Pass the ref to the Quagga container
        },
        area: { // defines rectangle of the detection/localization area
            top: "0%",    // top offset
            right: "0%",  // right offset
            left: "0%",   // left offset
            bottom: "0%"  // bottom offset
        },
        locator: {
            patchSize: "medium",
            halfSample: true
        },
        numOfWorkers: numOfCores,
        frequency: 10,
        constraints: {
            aspectRatio: 9 / 16,
            facingMode: "environment", // or user,
            focusMode: 'continuous',
        },
        decoder: {
            readers: decoder.value
        },
        locate: true // Important: This needs to be set to true to enable locator and visual feedback
    }, (err) => {
        if (err) {
            console.error('here error', err);
            return;
        }
        Quagga.start();
    });

    Quagga.onDetected((result) => {
        document.querySelector('.scanner-container > video').pause();

        if(props.decoder === 'code_128_reader') {
            // cut the first two 00 if the barcode is from a box
            scannedCode.value = result.codeResult.code.substring(2);
        } else {
            scannedCode.value = result.codeResult.code;
        }
        console.log(scannedCode.value, result.codeResult.code);

        const { play } = useBeep()
        play()
    });

    // Drawing the detection box
    Quagga.onProcessed((result) => {
        const drawingCtx = Quagga.canvas.ctx.overlay,
            drawingCanvas = Quagga.canvas.dom.overlay;

        if (result) {
            // This part is for drawing the box around the detected barcode
            if (result.boxes) {
                drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                result.boxes.filter(box => box !== result.box).forEach(box => {
                    Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "green", lineWidth: 2});
                });
            }

            // Drawing the box around the entire detected barcode
            if (result.box) {
                Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 2});
            }

            // Drawing the code line
            if (result.codeResult && result.codeResult.code) {
                Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 3});
            }
        }
    });
}

function completeScan() {
    emit('addToScannedItems', scannedCode.value);
    console.log(scannedCode.value);

    Quagga.stop();
    scannedCode.value = null;
}

function completeAndContinueScan() {
    document.querySelector('.scanner-container > video').play();
    emit('addToScannedItemsAndContinue', scannedCode.value);
    scannedCode.value = null;
}

function restartScan() {
    document.querySelector('.scanner-container > video').play();
    scannedCode.value = null;
}

function cancelScan() {
    Quagga.stop();
    scannedCode.value = null;
    emit('cancelScan');
}


onUnmounted(() => {
    Quagga.stop();
});

defineExpose({
    startScan
})

const decoder = computed(() => {
    const aux = [];
    aux.push(props.decoder);
    return aux;
});
</script>

<style>
.scanner-container > video {
    width: 100% !important;
    max-height: 100% !important;
}

.scanner-container > .drawingBuffer {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
    z-index: 300 !important;
}

/* Initial state of the element, before entering */
.slide-enter-from, .slide-leave-to {
    transform: translatey(100%); /* Slide in from left */
    opacity: 0;
}

/* Final state of the element, after entering */
.slide-enter-to, .slide-leave-from {
    transform: translatey(0%);
    opacity: 1;
}

/* Transition properties */
.slide-enter-active, .slide-leave-active {
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
</style>
