<template>
    <HugoLines />
    <Header />
    <div
        class="w-full flex flex-col bg-background-1 min-h-[100dvh] overflow-hidden relative"
    >
        <div class="w-full flex justify-between items-center p-4 box-border">
            <div class="flex">
                <img
                    src="../../assets/chevron-left-svgrepo-com.svg"
                    class="w-8 -ml-2"
                    @click="router.push({ name: 'users' })"
                />
                <div class="text-3xl font-medium">Edit User</div>
            </div>
        </div>
        <div class="w-full">
            <div v-if="loading">...loading2</div>
            <div v-else class="w-full">
                <div class="bg-red-400 text-white p-2" v-if="hasErrors">
                    Please fill in the details
                </div>
                <div class="w-full bg-white p-2">
                    <div class="m-2">
                        <label for="name">User name:</label>
                        <input
                            v-model="username"
                            type="text"
                            placeholder="Username"
                            class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-2"
                        />
                    </div>
                    <div class="m-2">
                        <label for="code">Email:</label>
                        <input
                            v-model="email"
                            type="text"
                            placeholder="Email"
                            class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-4"
                        />
                    </div>
                    <div class="m-2">
                        <label for="code">New password:</label>
                        <input
                            v-model="password"
                            type="text"
                            placeholder="Password"
                            class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-4"
                        />
                    </div>
                </div>
                <div class="p-2 mt-4">
                    <div class="flex justify-between items-center flex-col">
                        <label for="code"
                            >Magazin atribuit:

                            {{ userAssignedStore(userId) }}
                        </label>

                        <Button
                            label="Change store"
                            color="gold"
                            dimensions="small"
                            class="mt-4"
                            @click="openChangeStoreModal"
                        />
                    </div>

                    <div class="flex flex-col mt-8">
                        <label class="mb-1">Ofera permisiuni de administrator userului?</label>
                        <Toggle
                            name="isAdmin"
                            :modelValue="isAdmin"
                            :small="true"
                        />
                    </div>

                    <Button
                        label="Update user"
                        color="gold"
                        class="mt-8"
                        :disabled="disabled"
                        @click="editUser"
                    />
                </div>

                <Modal ref="modalRef">
                    <template #header>
                        <h3
                            class="text-2xl leading-6 font-medium text-gray-900 p-2"
                        >
                            Assign user
                        </h3>
                    </template>
                    <template #default>
                        <div class="bg-red-400 text-white p-2" v-if="hasErrors">
                            Please select a store
                        </div>
                        <div class="p-2 text-center">
                            <select
                                v-model="selectedStore"
                                class="w-full h-[40px] border-2"
                            >
                                <option
                                    v-for="store in stores"
                                    :value="store.id"
                                    :key="store.id"
                                >
                                    {{ store && store.name }}
                                </option>
                            </select>
                        </div>
                    </template>
                    <template #footer>
                        <Button
                            label="Assign user to store"
                            color="gold"
                            class="mt-4"
                            @click="assignStore"
                        />
                        <Button
                            label="Cancel"
                            color="grey"
                            class="mt-4"
                            @click="closeChangeStoreModal"
                        />
                    </template>
                </Modal>
            </div>
        </div>
    </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import HugoLines from "@/components/HugoLines.vue";
import Modal from "@/components/Modal.vue";
import Toggle from "@/components/Toggle.vue";
import Button from "@/components/Button.vue";

import { useStores } from "@/composables/useStores";
import { useUser } from "@/composables/useUser";
import { useUserStore } from "@/store/useUserStore";
import Swal from "sweetalert2";
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { storeToRefs } from "pinia";

const { getUserById, assignUserToStore, updateUser } = useUser();
const { getStores } = useStores();
const { user } = storeToRefs(useUserStore());

const router = useRouter();
const route = useRoute();

const loading = ref(true);
const userId = ref("");
const username = ref("");
const email = ref("");
const password = ref("");
const storeId = ref("");
const isAdmin = ref("");
const hasErrors = ref(false);
const storeUsers = ref([]);
const stores = ref([]);
const selectedStore = ref("");
const disabled = ref(false);

const modalRef = ref(null);

function openChangeStoreModal() {
    modalRef.value.openModal();
}

function closeChangeStoreModal() {
    selectedStore.value = "";
    modalRef.value.closeModal();
}

function fetchUserData() {
    const userId = route.params.userId;

    getUserById(userId)
        .then((res) => {
            loading.value = false;

            userId.value = res.id;
            username.value = res.name;
            email.value = res.email;
            isAdmin.value = res.is_admin;
            storeId.value = res.storeId;
        })
        .catch(() => {
            loading.value = false;
        });
}

function assignStore() {
    hasErrors.value = false;
    const payload = {
        storeId: selectedStore.value,
        userId: route.params.userId,
    };

    if (payload.storeId === "" || payload.userId === "") {
        hasErrors.value = true;
        return;
    }

    assignUserToStore(payload)
        .then(() => {
            modalRef.value.closeModal();
            Swal.fire({
                title: "Atribuit!",
                text: "Utilizatorul a fost atribuit cu succes magazinului.",
                icon: "success",
            });
            fetchUserData();
        })
        .catch(() => {
            Swal.fire({
                text: "Ceva nu a mers bine. Incearca din nou.",
                timer: 2000,
                timerProgressBar: true,
                icon: "error",
                showConfirmButton: false,
            });
        });
}

function editUser() {
    hasErrors.value = false;
    if (username.value === "" || email.value === "") {
        hasErrors.value = true;
        return;
    }

    const id = route.params.userId;
    const payload = {
        name: username.value,
        email: email.value,
        isAdmin: isAdmin.value,
        storeId: storeId.value,
        password: password.value,
    };

    updateUser(id, payload)
        .then(() => {
            Swal.fire({
                title: "Actualizat!",
                text: "Utilizatorul a fost actualizat cu succes.",
                icon: "success",
            });
        })
        .catch((err) => {
            console.log(err);

            Swal.fire({
                text: "Ceva nu a mers bine. Incearca din nou.",
                timer: 2000,
                timerProgressBar: true,
                icon: "error",
                showConfirmButton: false,
            });
        });
}

function userAssignedStore(userIdToSearch) {
    if (stores.value.length === 0 || loading.value) {
        return "...";
    }

    const auxStore = stores.value.find((store) =>
        store.users.some((user) => user.id === userIdToSearch)
    );

    if (!auxStore) {
        return "No store assigned";
    }

    return auxStore.name;
}

onMounted(() => {
    if (user.value && !user.value.is_admin) {
        router.push({ name: "dashboard" });
    }

    const id = route.params.userId;

    console.log(id);

    getUserById(id)
        .then((res) => {

            userId.value = res.id;
            username.value = res.name;
            email.value = res.email;
            isAdmin.value = res.is_admin;
            storeId.value = res.storeId;
        })
        .finally(() => {
            loading.value = false;
        });

    getStores()
        .then((res) => {
            stores.value = res;
        })
        .catch(() => {
            console.log("error");
        });
});
</script>
