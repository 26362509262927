<template>
    <div class="w-full bg-green-200 p-3 box-border mt-2 border border-black/10 rounded">
        <p>
            User: <span class="font-bold">{{ log.author.name }}</span>
        </p>
        <p>
            Actiune: <span class="font-bold">{{ log.action }}</span>
        </p>
        <p>
            Data: <span class="font-bold">{{ new Date(log.created_at).toLocaleString('ro-RO') }}</span>
        </p>
        <p>
            EAN Produs: <span class="font-bold">{{ log.invoiceOrderItem.ean }}</span>
        </p>
        <p>
            Nume Produs: <span class="font-bold">{{ log.invoiceOrderItem.name }}</span>
        </p>
        <p>
            Status Produs: <span class="font-bold">{{ log.invoiceOrderItem.status }}</span>
        </p>
        <p v-if="hasInvoiceOrder">
            Nume Invoice Order: <span class="font-bold">{{ invoiceOrder.name }}</span>
        </p>
        <p v-if="hasBox">
            Cutie: <span class="font-bold">{{ box.name }}</span>
        </p>
    </div>
</template>

<script setup>
import {computed, onMounted, ref} from "vue";
import {useInvoiceOrder} from "@/composables/useInvoiceOrder";

const {getInvoiceOrder, getBox} = useInvoiceOrder();

const props = defineProps({
    log: {
        type: Object,
        required: true
    }
});

const invoiceOrder = ref(null);
const box = ref(null);

const hasInvoiceOrder = computed(() => {
    return invoiceOrder.value !== null;
})

const hasBox = computed(() => {
    return box.value !== null;
})

onMounted(() => {
    if(props.log.invoice_order_id) {
        getInvoiceOrder(props.log.invoice_order_id).then(response => {
            invoiceOrder.value = response.data;
        });
    }

    if(props.log.invoice_order_id && props.log.invoice_order_box_id) {
        getBox(props.log.invoice_order_id, props.log.invoice_order_box_id).then(response => {
            box.value = response.data;
        });
    }
})
</script>
