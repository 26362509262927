<template>
    <div class="w-full bg-green-200 p-3 box-border mt-2 border border-black/10 rounded">
        <p>
            Actiune: <span class="font-bold">{{ log.action }}</span>
        </p>
        <p>
            Realizata de: <span class="font-bold">{{ log.author.name }}</span>
        </p>
        <p>
            Catre: <span class="font-bold">{{ log.user.name }}</span>
        </p>
        <p>
            Data: <span class="font-bold">{{ new Date(log.created_at).toLocaleString('ro-RO') }}</span>
        </p>
        <div>
            Ce a fost modificat:<br>
            <p class="font-bold" v-html="parseStoreData"></p>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    log: {
        type: Object,
        required: true
    }
});

const parseStoreData = computed(() => {
    if(!props.log.data) return JSON.stringify({});

    const parsed = JSON.parse(props.log.data);
    let text = '';
    parsed.forEach((item) => {
        text += `${item.prop}: ${item.from} -> ${item.to}`;
        if(parsed.indexOf(item) !== parsed.length - 1) text += '<br> ';
    });
    return text;
});
</script>
