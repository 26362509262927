<template>
    <div
        v-if="isVisible"
        class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4"
        @click.self="closeModal"
    >
        <div
            class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all max-w-lg w-full"
        >
            <header class="px-4 py-2 bg-gray-100 border-b">
                <slot name="header"> Default Header </slot>
            </header>
            <main class="p-4">
                <slot>
                    Default content goes here if no slot content is provided.
                </slot>
            </main>
            <footer class="px-4 py-2 bg-gray-100 border-t">
                <slot name="footer">
                    <button
                        class="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700 focus:outline-none"
                        @click="closeModal"
                    >
                        Close
                    </button>
                </slot>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: "BaseModal",
    data() {
        return {
            isVisible: false,
        };
    },
    methods: {
        openModal() {
            this.isVisible = true;
        },
        closeModal() {
            this.isVisible = false;
        },
    },
};
</script>
