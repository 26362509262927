<template>
    <HugoLines />
    <Header />
    <div
        class="w-full flex flex-col bg-background-1 min-h-[100dvh] overflow-hidden relative"
    >
        <div class="w-full flex justify-between items-center p-4 box-border">
            <div class="flex">
                <img
                    src="../../assets/chevron-left-svgrepo-com.svg"
                    class="w-8 -ml-2"
                    @click="router.push({ name: 'stores' })"
                />
                <div class="text-3xl font-medium">Edit Store</div>
            </div>
        </div>
        <div class="w-full">
            <div v-if="loading">...loading</div>
            <div v-else>
                <div class="bg-red-400 text-white p-2" v-if="hasErrors">
                    Te rog sa completezi toate campurile
                </div>

                <div class="w-full bg-white p-2">
                    <div class="m-2">
                        <label for="name"
                            >Nume magazin:
                            <input
                                type="text"
                                v-model="storeName"
                                class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-2"
                                required
                        /></label>
                    </div>
                    <div class="m-2">
                        <label for="code"
                            >Cod magazin:
                            <input
                                type="number"
                                v-model="storeCode"
                                class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-2"
                                required
                        /></label>
                    </div>

                    <div class="text-l p-4 w-full box-border">
                        <div class="w-full flex justify-between items-center">
                            <span class="text-lg font-bold">Useri magazin</span>
                            <Button label="Adauga" class="max-w-[100px]" color="gold" @click="openAssignUserModal"/>
                        </div>
                        <div v-if="noUsersAssigned">
                            Nu exista utilizatori atribuiti acestui magazin
                        </div>
                        <div class="m-2">
                            <ol
                                class="list-decimal"
                                :key="user.id"
                            >
                                <li v-for="user in storeUsers">{{ user.name }}</li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div class="px-4 py-2 bg-gray-100 border-t">
                    <Button
                        label="Salveaza"
                        color="gold"
                        class="mt-4"
                        :disabled="loading"
                        @click="editStore"
                    />
                </div>

                <Modal ref="modal">
                    <template #header>
                        <h3
                            class="text-2xl leading-6 font-medium text-gray-900 p-2"
                        >
                            Atribuie utilizator
                        </h3>
                    </template>
                    <template #default>
                        <div class="bg-red-400 text-white p-2" v-if="hasErrors">
                            Te rog sa alegi un utilizator
                        </div>
                        <div class="p-2 text-center">
                            <select
                                v-model="selectedUser"
                                class="w-full h-[40px] border-2"
                            >
                                <option
                                    v-for="user in allUsers"
                                    :value="user.id"
                                    :key="user.id"
                                >
                                    {{ user.name }}
                                </option>
                            </select>
                        </div>
                    </template>
                    <template #footer>
                        <Button
                            label="Atribuie utilizator unui magazin"
                            color="gold"
                            class="mt-4"
                            @click="assignUser"
                        />
                        <Button
                            label="Anuleaza"
                            color="grey"
                            class="mt-4"
                            @click="closeAssignUserModal"
                        />
                    </template>
                </Modal>
            </div>
        </div>
    </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import HugoLines from "@/components/HugoLines.vue";
import Modal from "@/components/Modal.vue";
import Button from "@/components/Button.vue";

import { useStores } from "@/composables/useStores";
import { useUser } from "@/composables/useUser";
import { useUserStore } from "@/store/useUserStore";
import Swal from "sweetalert2";
import { useRoute, useRouter } from "vue-router";
import { onMounted, ref, computed } from "vue";
import { storeToRefs } from "pinia";

const { getAllUsers, assignUserToStore } = useUser();
const { updateStore, getStore } = useStores();

const router = useRouter();
const route = useRoute();
const { user } = storeToRefs(useUserStore());

const loading = ref(true);
const storeName = ref("");
const storeCode = ref("");
const storeId = ref("");
const hasErrors = ref(false);
const storeUsers = ref([]);
const allUsers = ref([]);
const selectedUser = ref("");
// const disabled = ref(true)

const modal = ref(null);

function openAssignUserModal() {
    modal.value.openModal();
}

function closeAssignUserModal() {
    selectedUser.value = "";
    modal.value.closeModal();
}

function assignUser() {
    loading.value = true;

    hasErrors.value = false;
    const payload = {
        storeId: route.params.storeId,
        userId: selectedUser.value,
    };

    if (payload.storeId === "" || payload.userId === "") {
        hasErrors.value = true;
        return;
    }

    assignUserToStore(payload)
        .then(() => {
            storeUsers.value.push(allUsers.value.find((user) => user.id === selectedUser.value))

            modal.value.closeModal();
            Swal.fire({
                title: "Utilizator atribuit!",
                text: "Utilizatorul a fost atribuit cu succes magazinului.",
                icon: "success",
            });
        })
        .catch(() => {
            Swal.fire({
                text: "Ceva nu a mers bine. Incearca din nou.",
                timer: 2000,
                timerProgressBar: true,
                icon: "error",
                showConfirmButton: false,
            });
        })
        .finally(() => {
            loading.value = false;
        })
}

function editStore() {
    hasErrors.value = false;
    if (storeName.value === "" || storeCode.value === "") {
        hasErrors.value = true;
        return;
    }

    const id = route.params.storeId;
    const payload = { name: storeName.value, code: storeCode.value };
    updateStore(id, payload)
        .then(() => {
            Swal.fire({
                title: "Modificat!",
                text: "Magazinul a fost modificat cu succes.",
                icon: "success",
            });
        })
        .catch(() => {
            Swal.fire({
                text: "Ceva nu a mers bine. Incearca din nou.",
                timer: 2000,
                timerProgressBar: true,
                icon: "error",
                showConfirmButton: false,
            });
        });
}

const noUsersAssigned = computed(() => {
    return storeUsers.value.length === 0;
});

onMounted(() => {
    if (!user.value.is_admin) {
        router.push({ name: "dashboard" });
    }

    const id = route.params.storeId;

    getAllUsers()
        .then((res) => {
            allUsers.value = res;
        })
        .catch((err) => {
            console.log(err);
        });

    getStore(id)
        .then((res) => {
            loading.value = false;

            storeName.value = res.name;
            storeCode.value = res.code;
            storeUsers.value = res.users;
        })
        .catch(() => {
            loading.value = false;
        });
});
</script>
