<template>
    <label
        :for="`toggle-${name}`"
        class="relative cursor-pointer block"
        :class="cssClasses"
    >
        <input
            :id="`toggle-${name}`"
            type="checkbox"
            class="peer sr-only"
            :checked="modelValue"
            @change="$emit('update:modelValue', $event.target.checked)"
        />

        <span
            class="absolute inset-0 rounded-full bg-gray-300 transition peer-checked:bg-green-500"
        ></span>

        <span
            class="absolute inset-y-0 start-0 m-1 rounded-full bg-white transition-all"
            :class="ballCssClasses"
        ></span>
    </label>
</template>

<script>
export default {
    name: "Toggle",
    props: {
        name: {
            type: String,
            default: "",
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        cssClasses() {
            if (this.small) return "h-5 w-9";
            return "h-8 w-14";
        },
        ballCssClasses() {
            if (this.small) return "h-3 w-3 peer-checked:start-4";
            return "h-6 w-6 peer-checked:start-6";
        },
    },
};
</script>
