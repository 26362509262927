<template>
    <div class="w-full h-[100vh] flex flex-col items-center justify-between">
        <div class="w-full">
            <div class="w-full h-[10px] bg-default-1"></div>
            <div class="w-full h-[10px] bg-default-2"></div>
        </div>
        <div class="xs:max-w-[300px]">
            <img src="@/assets/logo.png" alt="Logo" class="xs:max-w-[300px] mb-[80px]" />
            <h1 class="text-3xl font-medium mb-4">Autentificare</h1>
            <input v-model="email" type="text" placeholder="Email" class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-4" />
            <input v-model="password" type="password" placeholder="Parola" class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-4" />
            <Button label="Autentificare" @click="handleLogin"/>
        </div>
        <div></div>
    </div>
</template>

<script setup>
import Button from "@/components/Button.vue";
import {useRouter} from "vue-router";
import {useAPI} from "@/composables/useAuth";
import {useUserStore} from "@/store/useUserStore";
import {ref} from "vue";
import {storeToRefs} from "pinia";
import Swal from "sweetalert2";

const router = useRouter()
const { doLogin } = useAPI();
const {user} = storeToRefs(useUserStore());

const email = ref('');
const password = ref('');

function handleLogin() {
    const payload = {
        email: email.value,
        password: password.value
    }

    doLogin(payload)
        .then((response) => {
            user.value = response.user
            localStorage.setItem('accessToken', response.accessToken);
            router.push({name: 'dashboard'});
        })
        .catch(error => {
            if(error.response.data.message === 'invalid_credentials') {
                Swal.fire({
                    icon: 'error',
                    title: 'Eroare',
                    text: 'Datele de autentificare folosite sunt incorecte'
                })
            }
        })
}
</script>
