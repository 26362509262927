<template>
    <div>
        <hr class="border-[1px] border-default-4" />
        <div class="w-full h-[50px] bg-white p-2 box-border rounded-[4px] text-[12px] flex justify-between items-center relative" v-touch:swipe.left="handleRemove" @mouseover="emit('selectItem', item)">
            <div class="w-full flex justify-between">
                <div class="flex flex-col flex-1">
                    <div class="flex">
                        <span>{{parseInt(index + 1) + ') '}}</span>
                        <span>{{item.ean}}</span>
                    </div>
                    <span>{{item.name}}</span>
                </div>
                <span>{{ isPendingUpload }}</span>
            </div>
            <div class="absolute right-3 left-auto top-1/2 -translate-y-1/2 px-2 py-1 rounded-[2px] bg-red-500 text-white text-center" v-if="isActive" @click="handleRemove">
                REMOVE
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    item: {
        type: Object,
        required: true
    },
    index: {
        type: Number,
        required: true
    },
    currentActiveItem: {
        type: [String, null],
        required: true
    }
})

const emit = defineEmits(['selectItem', 'remove-item'])

const isActive = computed(() => {
    return props.currentActiveItem === props.item;
})

const isPendingUpload = computed(() => {
    return props.item.status === 'PENDING_UPLOAD' ? 'PENDING UPLOAD' : ''
})

function selectItem() {
    emit('selectItem', props.item)
}

function handleRemove() {
    emit('selectItem', props.item);
    emit('remove-item', props.item);
}
</script>

<style scoped>

</style>
