<template>
    <HugoLines />
    <Header />


    <div v-if="loading">Loading...</div>

    <div
        class="w-full flex flex-col bg-background-1 min-h-[100dvh] overflow-hidden relative"
        v-else
    >
        <div class="w-full flex justify-between items-center p-4 box-border">
            <div class="flex">
                <img
                    src="../../assets/chevron-left-svgrepo-com.svg"
                    class="w-8 -ml-2"
                    @click="router.push({ name: 'dashboard' })"
                />
                <div class="text-3xl font-medium">Users</div>
            </div>
            <Button
                v-if="isCurrentUserAdmin"
                dimensions="small"
                label="Add user"
                @click="openAddUserModal"
            />
        </div>
        <div class="w-full flex flex-col">
            <div
                v-for="user in users"
                :key="user.id"
                class="flex justify-between p-4 bg-white rounded mt-1 cursor-pointer"
                v-touch:swipe.left="() => {doDeleteUser(user.id)}"
                @click="viewUser(user.id)"
            >
                <div>
                    <div>
                        <div>
                            {{ user.name }}
                        </div>
                    </div>
                    <div>
                        <div>
                            <font-awesome-icon :icon="['fas', 'store']" />
                            {{ user.store && user.store.name }}
                        </div>
                    </div>
                </div>
                <div class="flex justify-between items-center text-2xl">
                    <div class="text-center text-violet-600">
                        <font-awesome-icon :icon="['fas', 'pen']" />
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- ADD USER MODAL -->
    <Modal ref="modalRef">
        <template #header>
            <h3 class="text-2xl leading-6 font-medium text-gray-900 p-2">
                Add new user
            </h3>
        </template>
        <template #default>
            <div class="bg-red-400 text-white p-2" v-if="hasErrors">
                Please fill in the details
            </div>
            <div class="w-full bg-white p-2">
                <div class="m-2">
                    <label for="name"
                        >User name:
                        <input
                            type="text"
                            v-model="username"
                            class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-2"
                    /></label>
                </div>
                <div class="m-2">
                    <label for="code"
                        >Email:
                        <input
                            type="text"
                            v-model="email"
                            class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-2"
                    /></label>
                </div>
                <div class="m-2">
                    <label for="code"
                        >Password:
                        <input
                            type="text"
                            v-model="password"
                            class="w-full h-[50px] border border-default-1 rounded-md px-4 mb-2"
                    /></label>
                </div>

                <div class="bg-red-400 text-white p-2" v-if="hasErrors">
                    Please select a store
                </div>
                <div class="p-2 w-full flex justify-start flex-col">
                    <label>Store</label>
                    <select v-model="storeId" class="w-full h-[40px] border-2">
                        <option
                            v-for="store in stores"
                            :value="store.id"
                            :key="store.id"
                        >
                            {{ store.name }}
                        </option>
                    </select>
                </div>
                <div class="flex mt-6">
                    <label>Admin</label>
                    <Toggle
                        name="isAdmin"
                        :modelValue="isUserAddedAdmin"
                        :small="true"
                    />
                </div>
            </div>
        </template>
        <template #footer>
            <Button
                label="Add new user"
                color="gold"
                class="mt-4"
                @click="addUser"
            />
            <Button
                label="Cancel"
                color="grey"
                class="mt-4"
                @click="closeAddUserModal"
            />
        </template>
    </Modal>
</template>

<script setup>
import Header from "@/components/Header.vue";
import HugoLines from "@/components/HugoLines.vue";
import Modal from "@/components/Modal.vue";
import Toggle from "@/components/Toggle.vue";
import Button from "@/components/Button.vue";

import { useUser } from "@/composables/useUser";
import { useStores } from "@/composables/useStores";
import { useUserStore } from "@/store/useUserStore";

import Swal from "sweetalert2";
import router from "@/router";
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";

const { getAllUsers, deleteUser, createUser } = useUser();
const { getStores } = useStores();
const { user } = storeToRefs(useUserStore());

const users = ref([]);
const stores = ref([]);
const email = ref("");
const password = ref("");
const username = ref("");
const storeId = ref("");
const hasErrors = ref(false);
const loading = ref(true);
const modalRef = ref(null);
const isUserAddedAdmin = ref(false);

function refetchData() {
    getAllUsers()
        .then((res) => {
            loading.value = false;
            users.value = res;
        })
        .catch((err) => {
            loading.value = false;
            console.log(err);
        });

    getStores()
        .then((res) => {
            loading.value = false;
            stores.value = res;
        })
        .catch((err) => {
            loading.value = false;
            console.log(err);
        });
}

function openAddUserModal() {
    email.value = "";
    password.value = "";
    username.value = "";
    storeId.value = "";
    isUserAddedAdmin.value = false;
    modalRef.value.openModal();
}

function closeAddUserModal() {
    hasErrors.value = false;
    modalRef.value.closeModal();
}

function viewUser(id) {
    router.push({ name: "view-user", params: { userId: id } });
}

function doDeleteUser(id) {
    console.log("test");
    Swal.fire({
        title: "Sterge utilizator",
        text: "Esti pe cale sa stergi un utilizator. Esti sigur?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Da, sterge!",
        cancelButtonText: "Nu, pastreaza",
    }).then((result) => {
        if (result.isConfirmed) {
            deleteUser(id)
                .then(() => {
                    Swal.fire({
                        title: "Sters!",
                        text: "Utilizatorul a fost sters.",
                        icon: "success",
                    });
                    loading.value = false;
                    refetchData();
                })
                .catch(() => {
                    Swal.fire({
                        text: "Ceva nu a mers bine. Incearca din nou.",
                        timer: 2000,
                        timerProgressBar: true,
                        icon: "error",
                        showConfirmButton: false,
                    });
                });
        }
    });
}

function addUser() {
    hasErrors.value = false;
    if (
        username.value === "" ||
        email.value === "" ||
        password.value === "" ||
        storeId.value === ""
    ) {
        hasErrors.value = true;
        return;
    }
    const payload = {
        name: username.value,
        email: email.value,
        password: password.value,
        storeId: storeId.value,
        isAdmin: isUserAddedAdmin.value,
    };
    createUser(payload)
        .then(() => {
            modalRef.value.closeModal();
            Swal.fire({
                title: "Adaugat!",
                text: "Utilizatorul a fost adaugat cu succes.",
                icon: "success",
            });
            refetchData();
        })
        .catch(() => {
            Swal.fire({
                text: "Ceva nu a mers bine. Incearca din nou.",
                timer: 2000,
                timerProgressBar: true,
                icon: "error",
                showConfirmButton: false,
            });
        });
}

const isCurrentUserAdmin = computed(() => {
    if (!user.value) {
        return false;
    }
    return user.value.is_admin;
});

onMounted(() => {
    if (user.value && !user.value.is_admin) {
        router.push({ name: "dashboard" });
    }

    getAllUsers()
        .then((res) => {
            loading.value = false;
            users.value = res;
        })
        .catch((err) => {
            loading.value = false;
            console.log(err);
        });

    getStores()
        .then((res) => {
            loading.value = false;
            stores.value = res;
        })
        .catch((err) => {
            loading.value = false;
            console.log(err);
        });
});
</script>
