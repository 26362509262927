<template>
    <div
        class="w-full flex flex-col bg-background-1 h-[100dvh] overflow-hidden relative"
    >
        <div class="w-full bg-white">
            <HugoLines/>
            <Header/>
        </div>

        <div class="w-full flex flex-col px-5 box-border" v-if="user">
            <h1 class="text-3xl font-medium leading-9 mt-6">Meniu</h1>
                <div class="w-full grid grid-rows-2 grid-cols-2 gap-4 mt-6">
                    <router-link :to="{name: 'invoice-orders'}" class="rounded-[4px] p-6 box-border bg-white text-black flex items-center justify-center flex-col">
                        <img src="../assets/invoice.svg" class="w-10 mb-3" alt="invoice"/>
                        Invoice Orders
                    </router-link>

                    <router-link
                        v-if="user.is_admin"
                        :to="{name: 'users'}"
                        class="rounded-[4px] p-6 box-border flex flex-1 bg-white text-black items-center justify-center flex-col"
                    >
                        <img src="../assets/users.svg" class="w-10 mb-3" alt="invoice"/>
                        Useri
                    </router-link>

                    <router-link
                        v-if="user.is_admin"
                        :to="{name: 'stores'}"
                        class="rounded-[4px] p-6 box-border flex flex-1 bg-white text-black items-center justify-center flex-col"
                    >
                        <img src="../assets/shop.svg" class="w-10 mb-3" alt="invoice"/>
                        Magazine
                    </router-link>

                    <router-link
                        v-if="user.is_admin"
                        :to="{name: 'history', params: {page: 1}}"
                        class="rounded-[4px] p-6 box-border flex flex-1 bg-white text-black items-center justify-center flex-col"
                    >
                        <img src="../assets/history.svg" class="w-10 mb-3" alt="invoice"/>
                        Istoric
                    </router-link>

                    <div
                        @click="logout"
                        class="rounded-[4px] p-6 box-border flex flex-1 bg-white text-black items-center justify-center flex-col"
                    >
                        <img src="../assets/logout.svg" class="w-10 mb-3" alt="invoice"/>
                        Logout
                    </div>
                </div>
        </div>
    </div>
</template>

<script setup>
import HugoLines from "@/components/HugoLines.vue";
import Header from "@/components/Header.vue";
import {useRouter} from "vue-router";
import {useUserStore} from "@/store/useUserStore";
import {storeToRefs} from "pinia";
import {useAPI} from "@/composables/useAuth";

const {user} = storeToRefs(useUserStore());
const {doLogout} = useAPI();
const router = useRouter();

async function logout() {
    // await doLogout();
    localStorage.removeItem('accessToken');
    user.value = null;

    router.push({name: 'login'});
}
</script>
