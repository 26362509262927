import {defineStore} from "pinia";
import {computed, ref} from "vue";

export const useInvoiceOrderStore = defineStore('invoiceOrderStore', () => {
	const invoiceOrder = ref(null);

	const scannedItems = ref([]);
    const itemsPendingUpload = ref([]);

	const scanProgressCount = computed(() => {
		return scannedItems.value.length
	})

	const isScanComplete = computed(() => {
        if (!invoiceOrder.value) {
            return false
        }
		return scanProgressCount.value === invoiceOrder.value.items.length
	})

	return {invoiceOrder, scannedItems, scanProgressCount, isScanComplete, itemsPendingUpload}
}, {
	persist: {
		storage: localStorage,
		paths: ['itemsPendingUpload']
	}
})
