<template>
    <div class="flex w-full justify-between p-4 items-center">
        <router-link :to="{name: 'dashboard'}">
            <img
                src="@/assets/logo.png"
                alt="Logo"
                class="xs:max-w-[150px]"
            />
        </router-link>
        <div class="flex flex-col w-10" @click="router.push({name: 'dashboard'})">
            <div class="w-full h-1.5 bg-default-1 first:mt-0"></div>
            <div class="w-full h-1.5 bg-default-1 first:mt-0 mt-1"></div>
            <div class="w-full h-1.5 bg-default-1 first:mt-0 mt-1"></div>
        </div>
    </div>
</template>

<script setup>
import {useRouter} from "vue-router";

const router = useRouter();
</script>
