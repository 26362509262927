import { createRouter, createWebHistory } from "vue-router";

import Login from "@/views/Login.vue";
import Scan from "@/views/ScanItems.vue";
import UserListView from "@/views/Users/UsersListView.vue";
import UserView from "@/views/Users/UserView.vue";
import StoresListView from "@/views/Stores/StoresListView.vue";
import StoreView from "@/views/Stores/StoreView.vue";
import InvoiceOrdersView from "@/views/InvoiceOrdersView.vue";
import ScanInvoiceOrderView from "@/views/NewInvoiceOrderView.vue";
import InvoiceOrderView from "@/views/InvoiceOrderView.vue";
import BoxView from "@/views/BoxView.vue";
import DashboardView from "@/views/DashboardView.vue";
import HistoryView from "@/views/HistoryView.vue";

const routes = [
    {
        path: "/",
        name: "dashboard",
        component: DashboardView
    },
    {
        path: "/invoice-orders",
        name: "invoice-orders",
        component: InvoiceOrdersView,
    },
    {
        path: "/new-invoice-order",
        name: "new-invoice-order",
        component: ScanInvoiceOrderView,
    },
    {
        path: "/invoice-order/:invoiceID?",
        name: "invoice-order",
        component: InvoiceOrderView,
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/scan/:invoiceID?/:boxID?",
        name: "scan",
        component: Scan,
    },
    {
        path: "/view-box/:invoiceID?/:boxID?",
        name: "view-box",
        component: BoxView,
    },
    {
        path: "/users",
        name: "users",
        component: UserListView,
    },
    {
        path: "/view-user/:userId?",
        name: "view-user",
        component: UserView,
    },
    {
        path: "/stores",
        name: "stores",
        component: StoresListView,
    },
    {
        path: "/view-store/:storeId?",
        name: "view-store",
        component: StoreView,
    },
    {
        path: "/history/:page?",
        name: "history",
        component: HistoryView,
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
