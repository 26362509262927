<template>
    <button
        :label="label"
        type="button"
        class="rounded-md h-[50px]"
        :disabled="disabled"
        :class="[disabled ? 'opacity-50': '', styleClasses.buttonBg, styleClasses.buttonDimensions]"
    >
        <span v-if="!loading" :class="styleClasses.buttonText">{{label}}</span>
        <Spinner v-else />
    </button>
</template>

<script setup>
import Spinner from "@/components/Spinner.vue";
import {computed} from "vue";

const props = defineProps({
    dimensions: {
        type: String,
        default: 'big'
    },
    label: {
        type: String,
        required: true
    },
    loading: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    color: {
        type: String,
        default: 'black'
    }
})

const styleClasses = computed(() => ({
    buttonDimensions: {
        'w-full font-lg': props.dimensions === 'big',
        'w-auto font-sm px-3': props.dimensions === 'small',
    },
    buttonBg: {
        'bg-default-1': props.color === 'black',
        'bg-white': props.color === 'white',
        'bg-default-2': props.color === 'gold',
        'bg-default-5': props.color === 'grey',
    },
    buttonText: {
        'text-white': ['black', 'gold'].includes(props.color),
        'text-black': ['grey', 'white'].includes(props.color),
    }
}));
</script>
